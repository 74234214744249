import React, {Component} from "react";
import ChartistGraph from "react-chartist";
import Chart from "react-google-charts";
import axios from 'axios';
import configData from '../config.json';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contestants: {},
      visitingStats: {},
      fromStart: {},
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      var contestants;
      var topContestants;
      var visitingStats;
      var fromStart;

      const apiServer = configData.apiServer
      const getContestantsResp = await this.getContestants(apiServer);
      contestants = getContestantsResp.data;
      topContestants = contestants.contestants.sort((a, b) => (a.visited < b.visited) ? 1 : -1);
      topContestants = topContestants.slice(0, 5);
      this.setState({ contestants });
      this.setState({ topContestants });


      // const getTotalByDayResp = await this.getTotalContestantsByDay(apiServer);
      // contestantsByDay = getTotalByDayResp.data.contestants;
      // this.setState({ contestantsByDay });

      const visitingStatsResp = await this.getContestantStats(apiServer);
      visitingStats = visitingStatsResp.data.stats;
      this.setState({ visitingStats });

      const totalContestantsFromStart = await this.getTotalContestantsFromStart(apiServer);
      fromStart = totalContestantsFromStart.data.contestants;
      this.setState({ fromStart });

    } catch(e) {
      alert(e);
    }
    this.setState({ isLoading: false })
  }

  getContestants(apiServer) {
    const listContestants = configData.paths.listContestants;
    return axios.get(apiServer + listContestants);
  }

  // getTotalContestantsByDay(apiServer) {
  //   const getTotalByDay = configData.paths.getTotalByDay;
  //   var date = new Date();
  //   date.setHours(24,0,0,0);
  //   const todayTs = date.getTime() / 1000;
  //   var timestamps = Array(5).fill(todayTs);
  //   for (let i = 0; i < timestamps.length; i++) {
  //     timestamps[i] = timestamps[i] - 86400*i;
  //   }
  //   const payload = { timestamps: timestamps }
  //   return axios.post(apiServer + getTotalByDay, payload);
  // }

  getTotalContestantsFromStart(apiServer) {
    const getTotalByDay = configData.paths.getTotalByDay;
    var startDate = configData.startDate;
    var date = new Date();
    date.setHours(24,0,0,0);
    const tomorrowTs = date.getTime() / 1000;
    var dates = [];
    while (startDate <= tomorrowTs) {
      dates.push(startDate);
      startDate += 86400;
    }
    const payload = { timestamps: dates }
    return axios.post(apiServer + getTotalByDay, payload)
  }

  // getNewContestantsByDay(apiServer) {
  //   const getNewByDay = configData.paths.getNewContestantsByDay;
  //   var date = new Date();
  //   date.setHours(0,0,0,0);
  //   const todayTs = date.getTime() / 1000;
  //   var timestamps = Array(5).fill(todayTs);
  //   for (let i = 0; i < timestamps.length; i++) {
  //     timestamps[i] = timestamps[i] - 86400*i;
  //   }
  //   const payload = { timestamps: timestamps }
  //   return axios.post(apiServer + getNewByDay, payload);
  // }


  getContestantStats(apiServer) {
    const getStats = configData.paths.getStats;
    return axios.get(apiServer + getStats + '/?intervals=0-5,5-100,100-100000');
  }

  renderContestantList(contestants) {
    return [{}].concat(contestants).map(
      (contestant, i) =>
        <tr>
          <td>{contestant.email}</td>
          <td>{contestant.first_name}</td>
          <td>{contestant.last_name}</td>
          <td>{contestant.visited}</td>
        </tr>
    );
  }


  render() {
    var dates = [];
    var previousMonth = '';
    Object.keys(this.state.fromStart).map(ts => {
      var date = new Date((ts-86400)*1000);
      var d = date.toString();
      var month = d.split(' ')[1];
      if ( month === previousMonth ) {
        dates.push(undefined);
      } else {
        dates.push(month);
        previousMonth = month;
      }
    });

    var countFromStart = [];
    var contestantsFromStart = [];
    Object.entries(this.state.fromStart).forEach(([k, v]) => {
      countFromStart.push(v.count);
      contestantsFromStart.push(v.contestants);
    });

    var pieChartData = [['Registered Status', 'Contestant count']];
    pieChartData.push(['Registered', this.state.contestants.registered_count]);
    pieChartData.push(['Un-registered', this.state.contestants.unregistered_count]);

    const statsLableMappings = {
      '0-5': 'Less than 5',
      '5-100': '5 to 100',
      '100-10000': 'More than 100'
    };
    var statsLables = [];
    var statsCount = [];
    Object.entries(this.state.visitingStats).forEach(([k, v]) => {
      statsLables.push(statsLableMappings[k]);
      statsCount.push(v.count);
    });

    // Object.entries(this.state.visitingStats).forEach(([k, v]) => {
    //   if (v.count !== 0) {
    //     pieChartData.push([k, v.count])
    //   }
    // });

    // var newUsersDates = [];
    // Object.keys(this.state.newByDay).map(ts => {
    //   var date = new Date(0);
    //   date.setUTCSeconds(ts);
    //   newUsersDates.push(date.toDateString());
    // })
    // var countNewUsers = [];
    // var newUsers = [];
    // Object.entries(this.state.newByDay).forEach(([k, v]) => {
    //   countNewUsers.push(v.count);
    //   newUsers.push(v.contestants);
    // })

    // console.log(this.state.contestants.count);
    return (
      <>
        <Container fluid>
          <Row>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Contestants</p>
                        <Card.Title as="h4">{this.state.contestants.count}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-calendar-alt mr-1"></i>
                    As of today
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Registered on Cloud Portal</p>
                        <Card.Title as="h4">{this.state.contestants.registered_count}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-calendar-alt mr-1"></i>
                    As of today
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-time-alarm text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Unregistered on Cloud Portal</p>
                        <Card.Title as="h4">{this.state.contestants.unregistered_count}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-calendar-alt mr-1"></i>
                    As of today
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-09 text-primary"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Newly registered on Cloud Portal</p>
                        <Card.Title as="h4">{this.state.contestants.new_registrant_count}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats">
                    <i className="far fa-clock-o mr-1"></i>
                      Today
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Total Registered Contestants on Cloud Portal</Card.Title>
                  <p className="card-category">From Start</p>
                </Card.Header>
                <Card.Body>
                  <div className="ct-chart" id="chartHours">
                    <ChartistGraph
                      data={{
                        labels: dates,
                        series: [countFromStart],
                      }}
                      type="Line"
                      options={{
                        low: 0,
                        high: Math.max(...countFromStart),
                        showArea: true,
                        height: "245px",
                        axisX: {
                          showGrid: false,
                        },
                        lineSmooth: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        chartPadding: {
                          right: 50,
                        },
                        axisY: {
                          onlyInteger: true,
                        },
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              },
                            },
                          },
                        ],
                      ]}
                    />
                  </div>
                </Card.Body>
                <Card.Footer>
                  <div className="legend">
                    <i className="fas fa-circle text-info"></i>
                    Total contestants
                  </div>
                  <hr></hr>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Contestants</Card.Title>
                  <p className="card-category">Registered vs Un-registered on Cloud Portal</p>
                </Card.Header>
                <Card.Body>
                <div className="ct-chart ct-perfect-fourth" id="chartPreferences">
                  <Chart
                    height={'300px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={pieChartData}
                    options={{
                      // Just add this option
                      is3D: true,
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Contestant Stats</Card.Title>
                  <p className="card-category">Cloud Portal Visits</p>
                </Card.Header>
                <Card.Body>
                  <div className="ct-chart" id="chartActivity">
                    <ChartistGraph
                      data={{
                        labels: statsLables,
                        series: [statsCount],
                      }}
                      type="Bar"
                      options={{
                        seriesBarDistance: 10,
                        axisX: {
                          showGrid: false,
                        },
                        axisY: {
                          onlyInteger: true,
                        },
                        height: "245px",
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            seriesBarDistance: 5,
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              },
                            },
                          },
                        ],
                      ]}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-tasks">
                <Card.Header>
                  <Card.Title as="h4">Top 5 Contestants</Card.Title>
                  <p className="card-category">By usage on Cloud Portal</p>
                </Card.Header>
                <Card.Body>
                  <div className="table-full-width">
                    <Table>
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Visits</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.isLoading && this.renderContestantList(this.state.topContestants)}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
